<template>
  <div>
    <h3 ref="intro">
      What is a Complex Number?
    </h3>
    <p>
      A complex number is obtained by combining a real number with an imaginary number. Example of a complex number would be \(2+3i\), \(-1+4i\).
    </p>
    <h3 ref="types">
      Adding Complex Numbers
    </h3>
    <p>Let's say we want to add two complex numbers: \(z_1 = a_1 + b_1 i\) and \(z_2 = a_2 +b_2 i\).</p>
    <ul class="a">
      <li>
        <h5> Add Real Parts Separately </h5>
        The real part of the sum of the two complex numbers is given by the adding the real parts of the individual complex numbers.
        $$Re(Z_1 + Z_2) = a_1 + a_2 $$
      </li>
      <li>
        <h5> Add Imaginary Parts Separately </h5>
        The imaginary part of the sum of two complex number is given by the sum of the imaginary parts of the individual complex numbers, i.e.,
        $$Im(Z_1+ Z_2) = b_1 + b_2$$
      </li>
    </ul>
    <p>
      Thus, the addition of two complex numbers can be written as
      $$Z_1 + Z_2 = a_1 + a_2 + (b_1 + b_2)i$$
    </p>
    <br>
    <h3 ref="pg">
      MagicGraph | Adding Complex Numbers using Parallelogram Method
    </h3>
    <p>
      This MagicGraph offers a visually interactive illustration of the graphical method of adding two complex numbers. This method is also known as parallelogram method.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'AdditionOfComplexNumbers',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Adding Complex Numbers ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Complex Number?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Adding Complex Numbers', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Adding Complex Numbers',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
