import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 14, 10, -6],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  makeResponsive(brd1);
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Addition of Complex Numbers', '(Click to Pick a Point on Argand Plane)');
  placeLogo(brd1);
  var analytics =placeImage(brd1, '/assets/eraser.svg', -2.5, -5.25, 1.5, 0);
  analytics.setLabel('Erase & Restart');
  analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  analytics.on('over', function () {this.label.setAttribute({visible:true});});
  analytics.on('out', function () {this.label.setAttribute({visible:false});});

  var erase =placeImage(brd1, '/assets/zoom-in.svg', 1, -5.25, 1.5, 0);
  erase.setLabel('Tap to add')
  erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  erase.on('over', function () {this.label.setAttribute({visible:true});});
  erase.on('out', function () {this.label.setAttribute({visible:false});});
  //placeDash(board, Pt1, Pt2, width, cols)
  //brd1.create('axis', [[-1, 0], [1, 0]], {strokeWidth: 2, strokeColor: 'red'});
  brd1.suspendUpdate();
  var name = placeMiddleText(brd1, 6.5, 14, '');
  name.setAttribute({color:'green'});
  var des = placeMiddleText(brd1, 6.5, 14, '');
  des.setAttribute({color:'green'});
  brd1.options.layer['image'] =14;
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var j =0;
  var moveC =brd1.create('point', [0, 0],{name:'<b>Z_1+Z_2</b>', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', label:{visible:function(){if(j==0){return false}else{return true}}, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
  var PtA =brd1.create('point', [0, 0],{name:'<b>O</b>', face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fixed:true,label:{offset:[0, -15], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
  var PtB =brd1.create('point', [-4, 4],{name:'<b>Z_1</b> (Drag me!)', snapToGrid:true, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fixed:false, fillOpacity:0.5,label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
  var moveA= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
  var moveB= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
  var PtD =brd1.create('point', [3, 2],{name:'<b>Z_2</b> (Drag me!)',snapToGrid:true, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fillOpacity:0.5, label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
  //brd1.create('text', [function(){return PtB.X()*0.5-0.5*Math.sign(PtB.X());}, function(){return PtB.Y()*0.5+0.5;}, '<b>r_1</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
  //brd1.create('text', [function(){return PtD.X()*0.5-0.5*Math.sign(PtD.X());}, function(){return PtD.Y()*0.5+0.5;}, '<b>r_2</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
  brd1.create('segment', [PtB, moveA], {strokeColor:'blue', dash:1});
  brd1.create('segment', [PtD, moveB], {strokeColor:'red', dash:1});
  brd1.create('arrow', [PtA, PtD], {visible:true, strokeColor:'blue', strokeWidth:3});
  brd1.create('arrow', [PtA, PtB], {visible:true, strokeColor:'red', strokeWidth:3});
  brd1.create('arrow', [PtA, moveC], {strokeColor:'green', strokeWidth:3});
  //brd1.create('text', [-7, -1.5, function(){return 'Z_1 ='+ (PtB.X()).toFixed(2)+'+' + (PtB.Y()).toFixed(2) +'i'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
  var z1 = placeRightText(brd1, -3, -1.5, function(){return ''+ (PtB.X()).toFixed(2)+'+' + (PtB.Y()).toFixed(2) +'i'});
  z1.setAttribute({color:'red'});
  var t1 = placeMiddleText(brd1, -4, -2.5, 'Z1');
  t1.setAttribute({color:'red'});
  var t2 = placeMiddleText(brd1,  0, -2.5, 'Z2');
  t2.setAttribute({color:'blue'});
  var t3 = placeMiddleText(brd1,  4, -2.5, 'Z1+Z2');
  t3.setAttribute({color:'green', visible:function(){return j==1}});
  var plus=brd1.create('image', ['/assets/addition.svg', [-2.4, -1.9],[0.8, 0.8]], {opacity:1, fixed:true});
  //brd1.create('text', [-1, -1.5, function(){return 'Z_2 = '+ (-PtA.X()+PtD.X()).toFixed(2) + '+' + (-PtA.Y()+PtD.Y()).toFixed(2) +'i'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
  var z2 = placeLeftText(brd1, -1, -1.5, function(){return ''+ (-PtA.X()+PtD.X()).toFixed(2) + '+' + (-PtA.Y()+PtD.Y()).toFixed(2) +'i'});
  z2.setAttribute({color:'blue'});
  var sum =placeLeftText(brd1, 2, -1.5,  function(){return ' = '+ (-PtA.X()+PtD.X()+PtB.X()).toFixed(2) + '+' + (-PtA.Y()+PtD.Y()+PtB.Y()).toFixed(2) +'i'});
  sum.setAttribute({color:'green', visible:function(){return j==1}});
//brd1.create('text', [ 3, -1.5, function(){return ' = '+ (-PtA.X()+PtD.X()+PtB.X()).toFixed(2) + '+' + (-PtA.Y()+PtD.Y()+PtB.Y()).toFixed(2) +'i'}],{visible:true,CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
  brd1.create('segment',[[-8, -7],[-2, -7]],{fixed:true, strokeColor:'black', strokeWidth:3});
  var runb =function(){moveB.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
  var runc =function(){moveA.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
  var runa =function(){moveC.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 1000)}
  erase.on('down', function(){j=1; moveA.moveTo([PtB.X(), PtB.Y()]); moveB.moveTo([PtD.X(), PtD.Y()]); moveC.moveTo([0,0]); runb();runc();runa();});
  PtB.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
  PtD.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
  analytics.on('down', function(){j=0; PtB.moveTo([-4,4]);PtD.moveTo([3,2]); moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])})
  brd1.unsuspendUpdate();
},
}
export default Boxes;
